@import "style-guide";

.table {
  &__header {
    @include Table-Header-font;
    @include Table-Header-middle-column;

    text-align: left;

    &--origin {
      padding-left: 6*$u;
    }

    &--costs {
      text-align: center;
    }

    &--status {
      padding-right: 6*$u;
    }
  }

  &__column {
    @include Table-Row-middle-column;

    &--origin {
      padding-left: 6*$u;
    }

    &--status {
      padding-right: 6*$u;
    }
  }
}
