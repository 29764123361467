@import "style-guide";

.wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.icon {
  margin-right: 2*$u;
}

.count {
  width: 4*$u;
  height: 4*$u;
  background-color: $light;
  border-radius: 50%;
  color: $white;
  text-align: center;

  @include Avatar-Normal-font;
}

.label {
  @include Country-Text-font;

  margin-right: 2*$u;
  white-space: nowrap;
  line-height: 4*$u;

  &--isThin {
    font-weight: 500;
  }
}

.flag-icon {
  display: block;
  padding-left: 2*$u;
}
