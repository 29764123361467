@import "style-guide";

.table {
  position: relative;

  &__header {
    @include Table-Header-font;

    padding: 0 4*$u 2*$u 4*$u;
    text-align: left;

    &--checkbox {
      width: 5*$u;
      padding: 0 0 2*$u 4*$u;
    }

    &--country {
      width: 20%;
    }

    &--cost {
      width: 30*$u;
    }

    &--date {
      width: 30*$u;
    }
  }

  &__column {
    padding: 0 4*$u;

    &--checkbox {
      width: 5*$u;
      padding: 3*$u 0 3*$u 4*$u;
    }

    &--country {
      width: 20%;
    }

    &--cost {
      width: 30*$u;
    }

    &--date {
      width: 30*$u;
    }
  }
}
