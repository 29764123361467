@import "style-guide";

.table {
  position: relative;
  margin-bottom: 6*$u;

  &__header {
    @include Table-Header-font;

    padding: 0 4*$u 2*$u 4*$u;
    text-align: left;

    &--checkbox {
      width: 5*$u;
      padding: 0 0 2*$u 4*$u;
    }

    &--name {
      width: 20%;
    }

    &--locations {
      width: 20%;
    }

    &--update {
      min-width: 28*$u;
    }

    &--rule {
      min-width: 25*$u;
    }

    &--valid-period {
      min-width: 28*$u;
    }

    &--status {
      min-width: 24*$u;
    }

    &--controls {
      max-width: 16*$u;
    }
  }

  &__column {
    padding: 0 4*$u;

    &--checkbox {
      width: 5*$u;
      padding: 5*$u 0 5*$u 4*$u;
    }

    &--name {
      width: 20%;
    }

    &--locations {
      width: 20%;
    }

    &--update {
      min-width: 20*$u;
    }

    &--rule {
      min-width: 25*$u;
    }

    &--valid-period {
      min-width: 20*$u;
    }

    &--status {
      min-width: 24*$u;
    }

    &--controls {
      max-width: 16*$u;
    }
  }
}
