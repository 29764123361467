@import "style-guide";

.table {
  &__header {
    @include Table-Header-font;

    padding: 0 5*$u 2*$u 5*$u;
    text-align: left;

    &--checkbox {
      width: 5*$u;
      padding: 0 0 2*$u 5*$u;
    }

    &--linked,
    &--dedicated {
      text-align: center;
    }

    &--type {
      padding: 0 5*$u 2*$u 4*$u;
    }
  }

  &__column {
    &--checkbox {
      width: 5*$u;
      padding: 5*$u 0 5*$u 5*$u;
    }

    &--type {
      padding: 0 5*$u 0 4*$u;
    }

    &--any {
      padding: 0 5*$u;
    }

    &--plate-number {
      padding: 5*$u;
    }

    &--status {
      width: 8*$u;
      padding: 5*$u;
    }
  }
}
