@import "style-guide";

.form {
  padding-bottom: 2*$u;
}

.field {
  &--source,
  &--rate {
    width: 50%;
  }
}
