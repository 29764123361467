@import "style-guide";

.table {
  &__header {
    @include Table-Header-font;
    @include Table-Header-middle-column;

    text-align: left;

    &--name {
      @include Table-Header-left-column;
    }

    &--type {
      min-width: 20*$u;
      max-width: 24*$u;
    }
  }

  &__column {
    &--name {
      @include Table-Row-left-column;

      width: 45%;
    }

    &--any {
      @include Table-Row-middle-column;

      width: 20%;
    }

    &--type {
      @include Table-Row-middle-column;

      min-width: 20*$u;
      max-width: 24*$u;
    }

    &--controls {
      @include Table-Row-right-control-column;

      width: 8*$u;
    }
  }
}

.lock {
  margin: 0 3*$u;
}
