@import "style-guide";

$optionVerticalPadding: 2*$u;
$optionHorizontalPadding: 3*$u;

.option {
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  padding-right: $optionHorizontalPadding;

  @include Text-overflow-ellipsis;
  @include Dropdown-ListBold-font;

  &:hover {
    background-color: $optionHoverBackgroundColor;
  }
}

.overlay {
  min-width: 100%;
}

.tag {
  margin-right: $u;
  margin-bottom: $u;
}

.trigger {
  &--hasValues {
    padding-bottom: 3px; // fix tag padding
  }
}
