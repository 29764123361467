@import "style-guide";

.table {
  &__header {
    padding: 0 3*$u;

    &--type {
      padding: 0 3*$u 0 5*$u;
      flex-grow: 1;
    }

    &--expire-date {
      text-align: center;
    }
  }

  &__column {
    padding: 3*$u;

    &--type {
      padding: 0 3*$u 0 5*$u;
      flex-grow: 1;
    }

    &--expire-date {
      text-align: center;
    }

    &--actions {
      @include table-column-auto-adjust-width;

      padding: 0 5*$u 0 3*$u;
    }
  }
}
