@import "style-guide";

.model {
  @include Table-Row-font;
}

.trailer-link {
  @include TableRowPrimary-Link-font;

  text-decoration: underline;
}

.not-assigned {
  @include Table-Row-Secondary-font;
}
